<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button />
      <h5 class="pageTitle">About</h5>
    </div>
    <hr>

    <div class="msg">
      <section>
        <div>
          Goblin Bank is a family virtual bank. Goblin Bank's mission is to help our kids learn money concepts from an early age, by providing a simple and fun tool to help parents and kids sail through this learning journey together.
        </div>
      </section>

      <section>
        <h6>Terms of Use</h6>
        <hr>
        Please refer to our <span class="clickable alink" @click="this.$root.gotoRoute('/terms-and-conditions')">Terms of Use</span> for details.
      </section>

      <section>
        <h6>Privacy Policy</h6>
        <hr>
        Please refer to our <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span> for details.
      </section>

      <section>
        <h6>Basic Information</h6>
        <hr>
        Current version number: {{this.$config.versionName + '.' + this.$config.version.toString()}}
      </section>

      <section>
        <h6>Credits</h6>
        <hr>
        <ul>
          <li>fontawesome.com</li>
          <li>freepik.com</li>
          <li>bestanimations.com</li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>



export default {
  name: 'About',
  components: {

  },
  data(){
    return{

    }
  },
  methods:{

  },
  mounted() {
    document.title = "About Goblin Bank";
  }
}
</script>

<style scoped>
.msg{
  margin:20px 10px 10px 10px;
  padding:10px;
  font-size:13px;
}

section{
  margin-bottom:30px;
}

hr{
  margin-top:5px;
}


</style>
